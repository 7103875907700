import router from "@/router";

export function signinMutation(state, payload) {
  localStorage.setItem("access_token", payload.access_token);

  state.user = payload;

  setTimeout(() => {
    router.push({ name: "Suppliers" });
  }, 2000);
}

export function errorsMutation(state, payload) {
  state.errors = payload;

  setTimeout(() => {
    state.errors = [];
  }, 1000);
}

export function authMutation(state, payload) {
  state.user = payload;
}

export function signoutMutation(state) {
  localStorage.removeItem("access_token");
  state.user = [];
  router.go(0);
  // location.href = "/login";
}

export function continentsMutation(state, payload) {
  state.continents = payload;
}

export function countriesMutation(state, payload) {
  state.countries = payload;
}

export function factoriesMutation(state, payload) {
  state.factories = payload;
}

export function factoriesPageMutation(state, payload) {
  state.factories.current_page = payload;
}

export function productsMutation(state, payload) {
  state.products = payload;
}

export function productsPageMutation(state, payload) {
  state.products.current_page = payload;
}

export function categoriesMutation(state, payload) {
  state.categories = payload;
}

export function categoriesPageMutation(state, payload) {
  state.categories.current_page = payload;
}

export function subCategoriesMutation(state, payload) {
  state.sub_categories = payload;
}

export function subCategoriesPageMutation(state, payload) {
  state.sub_categories.current_page = payload;
}

export function accountsMutation(state, payload) {
  state.accounts = payload;
}

export function accountsPageMutation(state, payload) {
  state.accounts.current_page = payload;
}

export function accountActionMutation(state, payload) {
  state.account_action = payload;
  state.plan_action = {
    action: "",
    show: false,
    form: [],
  };
  state.drawer_show = payload.show;
}

export function accountMutation(state, payload) {
  state.account = payload;
}

export function reportsMutation(state, payload) {
  state.reports = payload;
}

export function aboutUsMutation(state, payload) {
  state.about_us = payload;
}

export function plansMutation(state, payload) {
  state.plans = [...payload];
}

export function planActionMutation(state, payload) {
  state.plan_action = payload;
  state.account_action = {
    action: "",
    show: false,
    form: [],
  };
  state.drawer_show = payload.show;
}
