import { api } from "@/services/axios";

export default {
  async loginAction({ commit }, payload) {
    await api
      .post(`/admin/login`, payload)
      .then((res) => {
        commit("signinMutation", res.data.data);
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors);
        console.log(err.response.status);
        console.log(err.response.data.errors);
      });
  },

  async logoutAction({ commit }) {
    await api
      .get(`/logout`)
      .then((res) => {
        commit("signoutMutation");
      })
      .catch((err) => {
        console.log(err.response.status);
        if (err.response.status === 403) return (location.href = "/forbidden");
        console.log(err.response.data.errors);
      });
  },

  async authAction({ commit }) {
    await api
      .get(`/user`)
      .then((res) => {
        commit("authMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err.response.status);
        if (err.response.status === 403) return (location.href = "/forbidden");
        console.log(err.response.data.errors);
      });
  },

  async getContinentsAction({ commit }) {
    await api
      .get(`/continents`)
      .then((res) => {
        commit("continentsMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.errors);
      });
  },

  async getContinentCountriesAction({ commit }, payload) {
    await api
      .get(`/continent-countries?query=${payload}`)
      .then((res) => {
        commit("countriesMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.errors);
      });
  },
};
