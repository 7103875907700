import { api } from "@/services/axios";

export default {
  async getPlansAction({ commit }) {
    await api
      .get(`/admin/plans`)
      .then((res) => {
        commit("plansMutation", res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors);
      });
  },

  async updatePlanAction({ commit, dispatch }, payload) {
    await api
      .post(`/admin/plan/update/${payload.id}`, payload)
      .then((res) => {
        dispatch("plansMutation");
        console.log(res.data.data);
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors);
      });
  },
};
