import Vue from "vue";
import VueRouter from "vue-router";
import middlewarePipeline from "./middleware-pipeline";
import { middleware } from "./middleware";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Login" },
  },
  {
    path: "/login",
    name: "Login",
    component: () => {
      return import("../views/Login.vue");
    },
  },
  {
    path: "/user",
    component: () => {
      return import("../layouts/Main.vue");
    },
    redirect: { name: "Factories" },
    meta: {
      middleware: [middleware],
    },
    children: [
      {
        path: "/suppliers",
        name: "Suppliers",
        component: () => {
          return import("../views/Factories.vue");
        },
        meta: {
          middleware: [middleware],
          parent: "Suppliers",
        },
      },
      {
        path: "/supplier/add",
        name: "Add Supplier",
        component: () => {
          return import("../views/AddSupplier.vue");
        },
        meta: {
          middleware: [middleware],
          parent: "Suppliers",
        },
      },
      {
        path: "/supplier/:id",
        name: "Supplier Details",
        component: () => {
          return import("../views/FactoryDetails.vue");
        },
        meta: {
          middleware: [middleware],
          parent: "Suppliers",
        },
      },

      {
        path: "/products",
        name: "Products",
        component: () => {
          return import("../views/Products.vue");
        },
        meta: {
          middleware: [middleware],
          parent: "Products",
        },
      },
      {
        path: "/product/add",
        name: "Add Product",
        component: () => {
          return import("../views/AddProduct.vue");
        },
        meta: {
          middleware: [middleware],
          parent: "Products",
        },
      },
      {
        path: "/product/edit/:id",
        name: "Edit Product",
        component: () => {
          return import("../views/EditProduct.vue");
        },
        meta: {
          middleware: [middleware],
          parent: "Products",
        },
      },
      {
        path: "/product/:id",
        name: "Product Details",
        component: () => {
          return import("../views/ProductView.vue");
        },
        meta: {
          middleware: [middleware],
          parent: "Products",
        },
      },
      {
        path: "/categories",
        name: "Categories",
        component: () => {
          return import("../views/Categories.vue");
        },
        meta: {
          middleware: [middleware],
          parent: "Categories",
        },
      },
      {
        path: "/category/:id",
        name: "Category Details",
        component: () => {
          return import("../views/CategoryDetails.vue");
        },
        meta: {
          middleware: [middleware],
          parent: "Categories",
        },
      },
      {
        path: "/reports",
        name: "Reports",
        component: () => {
          return import("../views/Reports.vue");
        },
        meta: {
          middleware: [middleware],
          parent: "Reports",
        },
      },
      {
        path: "/countries",
        name: "Countries",
        component: () => {
          return import("../views/Countries.vue");
        },
        meta: {
          middleware: [middleware],
          parent: "Countries",
        },
      },
      {
        path: "/user-management",
        name: "Account Management",
        component: () => {
          return import("../views/AccountManagement.vue");
        },
        meta: {
          middleware: [middleware],
          parent: "Account Management",
        },
      },
      {
        path: "/plans",
        name: "Plans",
        component: () => {
          return import("../views/Plans.vue");
        },
        meta: {
          middleware: [middleware],
          parent: "Plans",
        },
      },
      {
        path: "/settings",
        name: "Settings",
        component: () => {
          return import("../views/Settings.vue");
        },
        meta: {
          middleware: [middleware],
          parent: "Settings",
        },
      },
      {
        path: "/my-account",
        name: "My Account",
        component: () => {
          return import("../views/Account.vue");
        },
        meta: {
          middleware: [middleware],
          parent: "My Account",
        },
      },
      {
        path: "/about-us",
        name: "About Us",
        component: () => {
          return import("../components/AboutUs.vue");
        },
        meta: {
          middleware: [middleware],
          parent: "My Account",
        },
      },
      {
        path: "/privacy-policy",
        name: "Privacy Policy",
        component: () => {
          return import("../views/PrivacyPolicy.vue");
        },
        meta: {
          middleware: [middleware],
          parent: "Privacy Policy",
        },
      },
      {
        path: "/terms-and-conditions",
        name: "Terms And Conditions",
        component: () => {
          return import("../views/TermsAndConditions.vue");
        },
        meta: {
          middleware: [middleware],
          parent: "Terms And Conditions",
        },
      },
      {
        path: "/page-settings",
        name: "Page Settings",
        component: () => {
          return import("../views/PageSettings.vue");
        },
        meta: {
          middleware: [middleware],
          parent: "Page Settings",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const ctx = { from, next, to };
    const nextMiddleware = middlewarePipeline(ctx, middleware, 1);

    return middleware[0]({ ...ctx, next: nextMiddleware });
  }
  return next();
});

export default router;
