import Vue from "vue";
import Vuex from "vuex";
import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";
import actions from "./actions";
import factorys from "./actions/factorys";
import products from "./actions/products";
import categories from "./actions/categories";
import sub_categories from "./actions/sub-categories";
import accounts from "./actions/accounts";
import about_us from "./actions/about-us";
import reports from "./actions/reports";
import plans from "./actions/plans";

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions: {
    ...actions,
    ...factorys,
    ...products,
    ...categories,
    ...sub_categories,
    ...accounts,
    ...about_us,
    ...reports,
    ...plans,
  },
  modules: {},
});
