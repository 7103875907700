import { api } from '@/services/axios'

export default {

  async getCategoriesAction({commit}, payload) {
    let endpoint = `/categories`;
    if(payload) {
      endpoint = `/categories?page=${payload.page}&show=${payload.show}`;
    } 
    await api.get(endpoint)
    .then((res) => {
      commit('categoriesMutation', res.data.data)
    }).catch((err) => {
      console.log(err);
    });
  },

  async getCategoriesNoPaginateAction({commit}, payload) {
 
    await api.get(`/admin/categories`)
    .then((res) => {
      commit('categoriesMutation', res.data.data)
    }).catch((err) => {
      console.log(err);
    });
  },

  async addCategoryAction({commit,dispatch}, payload) {
   
    await api.post(`/admin/categories`, payload)
    .then((res) => {
      dispatch('getCategoriesAction')
    }).catch((err) => {
        commit('errorsMutation', err.response.data.errors)
    });
  },

  async showCategoryAction({commit,dispatch}, payload) {
    await api.get(`/admin/categories/${payload}`)
    .then((res) => {
      commit('categoriesMutation', res.data.data)
    }).catch((err) => {
        commit('errorsMutation', err.response.data.errors)
    });
  },

  async deleteCategoryAction({commit,dispatch}, payload) {
    await api.delete(`/admin/categories/${payload}`)
    .then((res) => {
      dispatch('getCategoriesAction', {show: 5})
    }).catch((err) => {
        commit('errorsMutation', err.response.data.errors)
    });
  },

  async updateCategoryAction({commit,dispatch}, payload) {
    await api.put(`/admin/categories/${payload.id}`, payload)
    .then((res) => {
      // dispatch('getCategoriesAction', {show: 5})
    }).catch((err) => {
        commit('errorsMutation', err.response.data.errors)
    });
  },

  async updateCategoryImageAction({commit,dispatch}, payload) {
    await api.post(`/admin/category/update-image`, payload.form)
    .then((res) => {
      dispatch('showCategoryAction', payload.id)
    }).catch((err) => {
        commit('errorsMutation', err.response.data.errors)
    });
  },

}