import { api } from "@/services/axios";

export default {
  async getProductsAction({ commit }, payload) {
    let endpoint = `/products`;
    if (payload) {
      endpoint = `/products?page=${payload.page}`;
    }
    await api
      .get(endpoint)
      .then((res) => {
        commit("productsMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async updateProductLimitAction({ commit }, payload) {
    await api
      .put(`/admin/update-product-limit/${payload.factory_id}`, payload)
      .then((res) => {
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async addFactoryProductAction({ commit, dispatch }, payload) {
    await api
      .post(`/admin/factory-product`, payload, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
        commit("errorsMutation", err.response.data.errors);
      });
  },

  async updateFactoryProductAction({ commit }, payload) {
    await api
      .put(`/admin/factory-product/${payload.id}`, payload)
      .then((res) => {
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteFactoryProductAction({ commit }, payload) {
    await api
      .delete(`/admin/factory-product/${payload}`)
      .then((res) => {
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async addFactoryProductImageAction({ commit }, payload) {
    await api
      .post(`/admin/factory-product/image`, payload)
      .then((res) => {
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteFactoryProductImageAction({ commit }, payload) {
    await api
      .delete(`/admin/factory-product/image/${payload}`)
      .then((res) => {
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async showProductAction({ commit }, payload) {
    await api
      .get(`/product/${payload}`)
      .then((res) => {
        commit("productsMutation", res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
