export default function () {
  return {
    user: [],
    errors: [],

    factories: [],
    products: [],
    continents: [],
    countries: [],
    categories: [],
    sub_categories: [],
    reports: [],
    accounts: [],
    account: [],
    about_us: [],
    plans: [],

    account_action: {
      action: "",
      show: false,
      form: [],
    },

    plan_action: {
      action: "",
      show: false,
      form: [],
    },

    drawer_show: false,
  };
}
