import Vue from 'vue'
import links from './links'
import errors from './errors'
import '../assets/scss/app.scss'

Vue.use(links)
Vue.use(errors)

Vue.component('custom-alert', () => import('./Alert.vue'))
Vue.component('breadcrumbs', () => import('./Breadcrumbs.vue'))
Vue.component('editable', () => import('./Editable'))