import { api } from '@/services/axios'

export default {
  async getSubCategoriesAction({commit, dispatch}, payload) {
    let endpoint = `/admin/sub-categories/${payload.category_id}`;
    if(payload.page != '') {
      endpoint = `/admin/sub-categories/${payload.category_id}?page=${payload.page}`;
    } 
    await api.get(endpoint)
    .then((res) => {
      commit('subCategoriesMutation', res.data.data)
    }).catch((err) => {
        commit('errorsMutation', err.response.data.errors)
    });
  },

  async addSubCategoryAction({commit, dispatch}, payload) {
    await api.post(`/admin/sub-categories`, payload)
    .then((res) => {
      console.log(res.data);
    }).catch((err) => {
        commit('errorsMutation', err.response.data.errors)
    });
  },

  async updateSubCategoryAction({commit, dispatch}, payload) {
    await api.put(`/admin/sub-categories/${payload.id}`, payload)
    .then((res) => {
      console.log(res.data);
      dispatch('getSubCategoriesAction', {category_id: payload.category_id} )
    }).catch((err) => {
        commit('errorsMutation', err.response.data.errors)
    });
  },

  async deleteSubCategoryAction({commit, dispatch}, payload) {
    await api.delete(`/admin/sub-categories/${payload.id}`, )
    .then((res) => {
      console.log(res.data);
      dispatch('getSubCategoriesAction', {category_id: payload.category_id} )
    }).catch((err) => {
        commit('errorsMutation', err.response.data.errors)
    });
  },

  async updateSubCategoryImageAction({commit,dispatch}, payload) {
    await api.post(`/admin/sub-category/update-image`, payload)
    .then((res) => {
      // dispatch('showCategoryAction', payload.id)
    }).catch((err) => {
        commit('errorsMutation', err.response.data.errors)
    });
  },

}