import { api } from "@/services/axios";

export default {
  async getFactoriesAction({ commit }, payload) {
    let endpoint = `/suppliers`;
    if (payload) {
      endpoint = `/suppliers?page=${payload.page}`;
    }
    await api
      .get(endpoint)
      .then((res) => {
        commit("factoriesMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async showFactoryAction({ commit }, payload) {
    await api
      .get(`/admin/accounts/${payload}`)
      .then((res) => {
        commit("factoriesMutation", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
