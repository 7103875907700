import { api } from "@/services/axios";

export default {
  async getAccountsAction({ commit }, payload) {
    let endpoint = `/admin/accounts`;
    if (payload) {
      endpoint = `/admin/accounts?page=${payload.page}`;
    }
    await api
      .get(endpoint)
      .then((res) => {
        commit("accountsMutation", res.data.data);
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors);
      });
  },

  async showAccountAction({ commit, dispatch }, payload) {
    await api
      .get(`/admin/accounts/${payload}`)
      .then((res) => {
        commit("accountMutation", res.data.data);
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors);
      });
  },

  async updateAccountAction({ commit, dispatch }, payload) {
    await api
      .post(`/admin/accounts/${payload.id}`, payload)
      .then((res) => {
        dispatch("getAccountsAction");
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors);
      });
  },

  async updateAccountPasswordAction({ commit, dispatch }, payload) {
    await api
      .post(`/admin/account/update-password`, payload)
      .then((res) => {
        // dispatch('getAccountsAction')
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors);
      });
  },

  async getFactoryAccountsAction({ commit }) {
    await api
      .get(`/admin/factory-accounts`)
      .then((res) => {
        commit("accountsMutation", res.data.data);
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors);
      });
  },

  async addAccountAction({ commit, dispatch }, payload) {
    await api
      .post(`/admin/accounts`, payload)
      .then((res) => {
        dispatch("getAccountsAction");
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors);
      });
  },

  async deleteAccountAction({ commit, dispatch }, payload) {
    await api
      .delete(`/admin/accounts/${payload}`)
      .then((res) => {
        dispatch("getAccountsAction");
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors);
      });
  },

  async subscribeAccountAction({ commit, dispatch }, payload) {
    await api
      .get(`/admin/account/subscribe/${payload}`)
      .then((res) => {
        dispatch("getFactoriesAction");
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors);
      });
  },
};
