import { api } from '@/services/axios'

export default {

  async getReportsAction({commit,dispatch}, payload) {
    await api.get(`/admin/reports`)
    .then((res) => {
      commit('reportsMutation', res.data.data)
    }).catch((err) => {
        commit('errorsMutation', err.response.data.errors)
    });
  },

  async postReportAction({commit,dispatch}, payload) {
    await api.post(`/admin/reports`, payload)
    .then((res) => {
      console.log(res.data)
      // let fileURL = URL.createObjectURL(new Blob([res.data]));
      let fileLink = document.createElement('a')

      fileLink.href = res.data.data.link
      fileLink.setAttribute('download', `@${res.data.data.type}-${new Date()}.xlsx`)
      document.body.appendChild(fileLink)

      fileLink.click()
      fileLink.remove()
    }).catch((err) => {
        commit('errorsMutation', err.response.data.errors)
    });
  },
          
}