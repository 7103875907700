import { api } from '@/services/axios'

export default {

  async showAboutUsAction({commit,dispatch}, payload) {
    await api.get(`/admin/about-us`)
    .then((res) => {
      commit('aboutUsMutation', res.data.data)
    }).catch((err) => {
        commit('errorsMutation', err.response.data.errors)
    });
  },

  async updateAboutUsAction({commit, dispatch}, payload) {
    await api.put(`/admin/about-us/${payload.id}`, payload)
    .then((res) => {
      // commit('aboutUsMutation', res.data.data)
    }).catch((err) => {
        commit('errorsMutation', err.response.data.errors)
    });
  },

}