import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        dark: '#000000',
        'dark-1': '#E0E0E0',
        'dark-2': '#333333',
        'dark-3': '#F5F5F5',
        light: '#ffffff',

        secondary: '#BDBDBD',
        'secondary-1' : '#424143',
        'secondary-2': '#828282',
        'secondary-3': '#545454',
        'secondary-4': '#BDBDBD',
        'secondary-5': '#F2F2F2',
        
        
        danger: '#F8835E',
        'danger-1': '#FF4444',
        success: '#85C751',
        'success-1': '#46B254',
        'success-2': '#9DD776',
        warning: '#FFC624',
        'warning-1': '#FEDA74',
        'warning-2': '#FFF5D8',
        'warning-3': '#F2C94C',
        primary: '#3966D8',
        'primary-1': '#33BDEC'

      },
    },
  },
})