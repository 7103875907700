const routes = [
  {
    name: 'Suppliers',
    route: 'Suppliers',
    icon: '',
    role: ['Admin', 'Staff']
  },
  {
    name: 'Products',
    route: 'Products',
    icon: '',
    role: ['Admin', 'Staff']
  },
  {
    name: 'Categories',
    route: 'Categories',
    icon: '',
    role: ['Admin', 'Staff']
  },
  {
    name: 'Reports',
    route: 'Reports',
    icon: '',
    role: ['Admin']
  },
  {
    name: 'User Management',
    route: 'Account Management',
    icon: '',
    role: ['Admin']
  },
  {
    name: 'Countries',
    route: 'Countries',
    icon: '',
    role: ['Admin']
  },
  {
    name: 'Plans',
    route: 'Plans',
    icon: '',
    role: ['Admin']
  },
  {
    name: 'CMS',
    route: '',
    icon: '',
    role: ['Admin'],
    children: [
      {
        name: 'About Us',
        route: 'About Us',
        icon: '',
        role: ['Admin'],
      },
      {
        name: 'Privacy Policy',
        route: 'Privacy Policy',
        icon: '',
        role: ['Admin'],
      },
      {
        name: 'Terms And Conditions',
        route: 'Terms And Conditions',
        icon: '',
        role: ['Admin'],
      },

      {
        name: 'Page Settings',
        route: 'Page Settings',
        icon: '',
        role: ['Admin'],
      },
    ]
  },
]

export default {
  install(Vue) {
    Vue.prototype.$links = routes
  }
}